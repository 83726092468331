/**
 * @description Цели Яндекс Аналитики.
 */
export enum EGoalsAnalytics {
  /**
   * @description Вызов номера телефона на мобильном разрешении.
   * @type {EGoalsAnalytics.CALLS_MOBILE}
   */
  CALLS_MOBILE = 'calls_mobile',

  /**
   * @description Заявка на ремонт через ФОС.
   * @type {EGoalsAnalytics.REPAIR_REQUEST}
   */
  REPAIR_REQUEST = 'repair_request',

  /**
   * @description Заявка на консультацию через ФОС FAQ.
   * @type {EGoalsAnalytics.GET_CONSULTATION}
   */
  GET_CONSULTATION = 'get_consultation',
}
